<template>
  <div
    class="app-background"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div class="auth-form">
      <img alt="logo" class="logo" :src="logo" />
      <h3 class="heading">Sign in</h3>
      <a-form
        layout="vertical"
        :model="form"
        :rules="rules"
        name="login"
        class="user-form"
        @finish="onSubmit"
      >
        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="email" autocomplete="off">
              <a-input v-model:value="form.email" placeholder="Enter email" />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="password" autocomplete="off">
              <a-input-password
                v-model:value="form.password"
                placeholder="Password"
              />
            </a-form-item>
          </div>
        </div>

        <div>
          <div class="signin-btn">
            <a-button
              type="primary"
              size="large"
              html-type="submit"
              class="btn-block"
            >
              <span>Sign in</span>
            </a-button>
          </div>

          <div class="middle">
            <div class="divider"></div>
            <div class="continue">OR</div>
            <div class="divider"></div>
          </div>
          <div class="ms-logo">
            <a-button
              style="margin-right: 105px; margin-left: 3px"
              class="btn-block"
              @click="AzureSignIn"
            >
              <img :src="mslogo" alt="" class="ms-logo" />
              <span style="font-weight: bold"
                >Continue with Microsoft Account</span
              >
            </a-button>
          </div>
        </div>

        <div class="buttons-w form-link mt-5">
          <!-- <router-link to="/signup">
            <small>Don't have an account? Click here to signup</small>
          </router-link> -->

          <div class="buttons-w">
            <router-link to="/forgot-password">
              <small>Forgot Password?</small>
            </router-link>
          </div>

          <div v-show="this.showVerifyRoute" class="buttons-w mt-2">
            <router-link to="/resend-verification">
              <small>Resend email Verification?</small>
            </router-link>
          </div>
          <div v-if="this.OTPModal === true">
            <div>
              <otpModal @close="ModalClose" />
            </div>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import mslogo from '../../assets/img/ms-logo.png';
import { mapActions, mapGetters } from 'vuex';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import otpModal from './OTPModal.vue';

export default {
  setup() {
    const store = useStore();
    const rules = {
      email: [
        {
          required: true,
          type: 'email',
          trigger: 'submit',
          message: 'email is required',
        },
      ],
      password: [
        { required: true, trigger: 'submit', message: 'password is required' },
      ],
    };

    const toast = useToast();

    return {
      rules,
      logo: require('src/assets/img/assembly-cp-logo.png'),
      backgroundImage: require('src/assets/img/backgroundImage.png'),
      mslogo,
      store,
      toast,
    };
  },
  computed: {
    ...mapGetters(['msalInstance', 'accessToken', 'isOtpSetup']),
  },
  created() {
    this.createMsalInstance();
    // this.SignOut();
  },
  components: {
    otpModal,
  },
  data() {
    return {
      OTPModal: false,
      showVerifyRoute: false,
      account: undefined,
      form: {
        email: '',
        password: '',
      },
      otpSentMessage:
        'We have just sent a 6-digit OTP to your email address. Please check your inbox and use the provided OTP for verification.',
    };
  },
  methods: {
    ...mapActions([
      'userLogin',
      'createMsalInstance',
      'azureSignIn',
      'azureSignUp',
      'userLogout',
      'clearState',
    ]),
    async onSubmit() {
      if (
        await this.userLogin({
          email: this.form.email,
          password: this.form.password,
        })
      ) {
        console.log('OTP Setup: ', this.isOtpSetup);
        this.OTPModal = true;
        this.toast.success(this.otpSentMessage);
      }
      this.showVerifyRoute = true;
    },
    ModalClose() {
      this.clearState();
      this.OTPModal = false;
    },
    async AzureSignIn() {
      await this.msalInstance
        .loginPopup({
          scopes: ['openid', 'profile', 'User.Read', 'email'],
          prompt: 'select_account',
        })
        .then((res) => {
          const myAccounts = this.msalInstance.getAllAccounts();
          const payload = {
            access_token: res.accessToken,
          };
          this.account = myAccounts[0];
          this.msalInstance.setActiveAccount(this.account);
          this.azureSignIn(payload);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
          this.store.dispatch('clearState');
        });
    },

    // async callAccessToken (account) {
    //     this.account = account;
    //     console.log(this.account);
    //     console.log('Getting storage data');
    //     await this.getAccessToken();
    //   },

    // async getAccessToken() {
    //     let request = {
    //       scopes: ['User.Read'],
    //       // scopes: ['openid', 'profile', 'User.Read', 'email'],
    //     };
    //     const msalInstance = this.msalInstance;
    //     try {
    //       let tokenResponse = await msalInstance.acquireTokenSilent(request);
    //       console.log('Token Silent');
    //       console.log("tokenResponse",tokenResponse);
    //       console.log('setAccessToken', tokenResponse.accessToken);
    //     } catch (error) {
    //       console.error(
    //         'Silent token acquisition failed. Using interactive mode',
    //       );
    //       console.error(error);
    //       let tokenResponse = await this.msalInstance.acquireTokenPopup(request);
    //       console.log('Token Popup');
    //       console.log(tokenResponse);
    //       console.log(
    //         `Access token acquired via interactive auth ${tokenResponse.accessToken}`,
    //       );
    //       console.log('setAccessToken', tokenResponse.accessToken);
    //     }
    //   },
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
  overflow: hidden;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  opacity: 0.9;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}
.ms-logo {
  height: 15px;
  margin-bottom: 3px;
  margin-right: 8px;
  align-content: center;
}
.signin-btn {
  margin-left: 0px;
}
.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.user-form {
  width: 80%;
  margin-top: 20px;
}

.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
.middle {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.continue {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707a8a;
}
.divider {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  height: 1px;
  flex: 1;
  background-color: #eaecef;
}

.app-background {
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}

.app-background::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
